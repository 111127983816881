import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import logo from '../../images/ns-logo.png';

// eslint-disable-next-line react/prefer-stateless-function
export default class Support extends Component {

  render() {
    return (
      <Card className='centered-card'>
        <CardBody>
          <CardTitle
            className='text-center'
            tag='h3'
          >
            <img
              src={logo}
              width='auto'
              height='50'
              alt='Northstrat'
            />
          </CardTitle>
          <CardText>
                To provide feedback or request support, please contact
            <a href='mailto: mobileapp@northstrat.com'> us </a>
                and someone from our team will be in touch with you.
            <br/>
            <br/>
            <a href='mailto: mobileapp@northstrat.com'>mobileapp@northstrat.com</a>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}
