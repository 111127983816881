import decode from 'jwt-decode';
import { toast } from 'react-toastify';
import appConfig from '../../config';

const API_URL = appConfig.apiGateway.URL;
const ADMIN_GROUP = appConfig.userGroupMappings.ADMIN;

export default class AuthService {
  /**
   * @description Get a token from api server using the fetch api
   * @param {*} username
   * @param {*} password
   * @returns {Promise}
   * @memberof AuthService
   */
  login = async (username, password) => {
    try {
      const data = await this.fetch('/authenticate', {
        method: 'POST',
        body: JSON.stringify({
          username,
          password
        })
      });
      this.setIdToken(data.token.idToken);
      this.setAccessToken(data.token.accessToken);
      this.setRefreshToken(data.token.refreshToken);
      return data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description Submits request to create account
   * @param {string} username
   * @param {string} password
   * @param {string} firstname
   * @param {string} lastname
   * @returns {Promise}
   * @memberof AuthService
   */
  signUp = async (username, password, firstname, lastname) => {
    try {
      const res = await this.fetch('/signup', {
        method: 'POST',
        body: JSON.stringify({
          username,
          password,
          firstname,
          lastname
        })
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description Submits request to create account
   * @param {string} username
   * @param {string} code
   * @returns {Promise}
   * @memberof AuthService
   */
  confirm = async (username, code) => {
    try {
      const res = await this.fetch('/confirm', {
        method: 'POST',
        body: JSON.stringify({
          username,
          code
        })
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description Refresh the user's session using the stored refreshToken
   * @param {string} email
   * @param {string} refreshToken
   * @returns {boolean} true if refresh was successful, false if it was not
   * @memberof AuthService
   */
  refreshSession = async (email, refreshToken) => {
    try {
      const newTokens = await this.fetch('/refreshSession', {
        method: 'POST',
        body: JSON.stringify({
          email,
          refreshToken
        })
      }, true);
      if (!this.isTokenExpired(newTokens.data.access_token)) {
        this.setIdToken(newTokens.data.id_token);
        this.setAccessToken(newTokens.data.access_token);
        this.setRefreshToken(newTokens.data.refresh_token);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

   /**
   * @description Check if accessToken is expired, if so, attempt to use refreshToken
   * to get new tokens. If refresh is successful, store new tokens. If there is an
   * issue refreshing the user's session, sign them out to make them re-authenticate.
   * @returns {boolean} true if the user should be signed out, false to leave them signed in
   * @memberof AuthService
   */
  shouldSignOut = async () => {
    const token = await this.getAccessToken();
    if (token && this.isTokenExpired(token)) {
      const refreshToken = await this.getRefreshToken();
      const profile = await this.getProfile();
      const email = `${profile.email}`;
      try {
        const refreshed = await this.refreshSession(email, refreshToken);
        if (refreshed) { // session was refreshed, stay signed-in
          return false;
        }
        // session not refreshed, sign-out
        this.signout(true);
        return true;
      } catch (error) {
        this.signout(true);
        return true;
      }
    } else if (!token) { // no access token
      this.signout(true);
      return true;
    } else { // token still valid
      return false;
    }
  };

  /**
   * @description Checks if there is a saved token and it's still valid
   * @returns {boolean}
   * @memberof AuthService
   */
  loggedIn = () => {
    const token = this.getToken();
    return token && !this.isTokenExpired(token);
  };

  /**
   * @description Checks if the token is valid
   * @param {*} token
   * @returns {boolean}
   * @memberof AuthService
   */
  isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      const now = Date.now() / 1000;
      if (decoded.exp < now) {
        return true;
      }
      return false;
    } catch (error) {
      return true;
    }
  };

  /**
   * @description Saves user token to localStorage
   * @param {*} token
   * @memberof AuthService
   */
  setIdToken = (token) => {
    localStorage.setItem('id_token', token);
  };

  /**
   * @description Retrieves the user token from localStorage
   * @returns
   * @memberof AuthService
   */
  getToken = () => {
    return localStorage.getItem('id_token');
  };

  /**
   * @description Saves user token to localStorage
   * @param {*} token
   * @memberof AuthService
   */
  setAccessToken = (token) => {
    localStorage.setItem('access_token', token);
  };

  /**
   * @description Retrieves the user token from localStorage
   * @returns
   * @memberof AuthService
   */
  getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

   /**
   * @description Saves refresh token to AsyncStorage
   * @param {*} token
   * @memberof AuthService
   */
  setRefreshToken = (token) => {
    localStorage.setItem('refresh_token', token);
  };

  /**
   * @description Retrieves the refresh token from AsyncStorage
   * @returns
   * @memberof AuthService
   */
  getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };

  /**
   * @description Decodes and verifies the token
   * @returns
   * @memberof AuthService
   */
  verifyToken = (token) => {
    const decoded = decode(token.idToken);
    if (decoded) {
      return true;
    }
    return false;
  };

  /**
   * @description Clear tokens from localStorage
   * @param {boolean=} optShowExpired
   * @memberof AuthService
   */
  signout = (optShowExpired) => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    if (optShowExpired) {
      toast.warning('Your session has expired, please sign-in again.', { autoClose: 8000 });
    }
  };

  /**
   * @description Decode the token
   * @returns {Object}
   * @memberof AuthService
   */
  getProfile = () => {
    return decode(this.getToken());
  };

  /**
   * @description Decode the token
   * @returns {Array.<string>}
   * @memberof AuthService
   */
  getGroups = () => {
    const profile = decode(this.getToken());
    return profile['cognito:groups'];
  };

  /**
   * @description Decode the token
   * @returns {Array.<string>}
   * @memberof AuthService
   */
  isAdmin = () => {
    const groups = this.getGroups();

    if (groups) {
      return groups.some((group) => {
        return group === ADMIN_GROUP;
      });
    }
    return false;
  };

  /**
   * @description Get the user id
   * @returns {string}
   * @memberof AuthService
   */
  getUserId = () => {
    const jwt = this.getAccessToken();
    const decodedJWT = decode(jwt);
    return decodedJWT.sub;
  };

  /**
   * @description Performs api calls sending the required authentication headers
   * @param {string} endpoint
   * @param {Object} options
   * @param {boolean=} optBypass
   * @returns {Promise}
   * @memberof AuthService
   */
  fetch = async (endpoint, options, optBypass) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json, multipart/form-data'
    };

    if (!optBypass) {
      const loggedIn = await this.loggedIn();
      const token = await this.getAccessToken();
      if (loggedIn) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    try {
      const res = await fetch(`${API_URL}${endpoint}`, {
        headers,
        ...options
      });
      let response = await res.json();
      response = this.checkStatus(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description Raises an error in case response status is not a success
   * @param {Object} response
   * @returns {Object}
   * @memberof AuthService
   */
  checkStatus = (response) => {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      return response;
    }
    let errMessage = '';
    if (response && response.message) {
      errMessage = response.message;
    } else if (response.data && response.data.message) {
      errMessage = response.data.message;
    } else {
      errMessage = 'No error message provided';
    }
    const error = new Error(errMessage);
    error.response = response;
    throw error;
  };
}
