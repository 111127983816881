import React, { Component } from 'react';
import Moment from 'moment';
import { Button, Col, CustomInput, FormGroup, Input, Label, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DateTimePicker, DropdownList } from 'react-widgets';
import { toast } from 'react-toastify';
import UserSearch from '../Utilities/UserSearch';
import AuthService from '../Authentication/AuthService';

const regionOptions = ['All', 'Colorado', 'Virginia'];

export default class EventModal extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    this.state = { event: props.event || {} };
  }

  componentDidMount() {
    const { event } = this.props;
    if (!event.eventId) {
      this.setState({ newEvent: true });
    }
  }

  addEvent = async () => {
    const { event } = this.state;
    const convertedEvent = this.convertEventDates(event);
    convertedEvent.creationDate = new Date().valueOf();
    try {
      await this.Auth.fetch('/calendar', {
        method: 'POST',
        body: JSON.stringify(convertedEvent)
      });
      this.closeEventModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  updateEvent = async () => {
    const { event } = this.state;
    const convertedEvent = this.convertEventDates(event);
    try {
      await this.Auth.fetch('/calendar', {
        method: 'PUT',
        body: JSON.stringify(convertedEvent)
      });
      this.closeEventModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  deleteEvent = async () => {
    const { event } = this.state;
    const body = JSON.stringify({
      eventId: event.eventId,
      creationDate: event.creationDate
    });
    try {
      await this.Auth.fetch('/calendar', {
        method: 'DELETE',
        body
      });
      this.closeEventModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Closes the modal
   */
  closeEventModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  /**
   * @description Updates the active user state
   * @param {*} e
   */
  updateField = (e) => {
    const { name, value } = e.target;
    const { event } = this.state;
    event[name] = value;
    this.setState({ event });
  };

  updateDateField = (date, name) => {
    const { event } = this.state;
    event[name] = date;
    this.setState({ event });
  };

  updateDropdownField = (region, name) => {
    const { event } = this.state;
    event[name] = region;
    this.setState({ event });
  };

  updateCheckboxField = (e) => {
    const { name, checked } = e.target;
    const { event } = this.state;
    event[name] = checked;
    this.setState({ event });
  };

  /**
   * @description Converts a date from Javascript Date to Unix Epoch
   * @param {Object} event
   */
  convertEventDates = (event) => {
    const tempEvent = { ...event };
    tempEvent.start = Moment.utc(tempEvent.start).valueOf();
    tempEvent.end = Moment.utc(tempEvent.end).valueOf();
    return tempEvent;
  };

  render() {
    const { event, newEvent } = this.state;

    return (
      <>
        <ModalHeader>{`${event.title || 'Add New Event'}`}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='title'>Event Title</Label>
            <Input
              name='title'
              value={event.title}
              onChange={this.updateField}
            />
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for='start'>Start</Label>
              <DateTimePicker
                value={event.start ? Moment(event.start).toDate() : null}
                onChange={(date) => {
                  this.updateDateField(date, 'start');
                }}
              />
            </Col>
            <Col>
              <Label for='end'>End</Label>
              <DateTimePicker
                value={event.end ? Moment(event.end).toDate() : null}
                onChange={(date) => {
                  this.updateDateField(date, 'end');
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for='region'>Region</Label>
            <DropdownList
              data={regionOptions}
              name='region'
              value={event.region}
              onChange={(region) => {
                this.updateDateField(region, 'region');
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for='location'>Location</Label>
            <Input
              name='location'
              value={event.location}
              onChange={this.updateField}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput
              id='olympics'
              type='checkbox'
              name='olympics'
              label='Northstrat Olympics Event'
              checked={event.olympics}
              onChange={this.updateCheckboxField}
            />
          </FormGroup>
          {event.olympics && (
            <FormGroup>
              <Label for='location'>Organizers</Label>
              <UserSearch
                selected={event.organizers}
                employees
                users
                multiple
                onChange={(organizers) => {
                  event.organizers = organizers;
                  this.setState({ event });
                }}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          {!newEvent && (
            <Button
              color='danger'
              onClick={this.deleteEvent}
            >
              Delete
            </Button>
          )}
          {newEvent && (
            <Button
              color='primary'
              onClick={() => {
                this.addEvent();
              }}
            >
              Add
            </Button>
          )}
          {!newEvent && (
            <Button
              color='primary'
              onClick={() => {
                this.updateEvent();
              }}
            >
              Save
            </Button>
          )}
          <Button
            color='secondary'
            onClick={() => {
              this.closeEventModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </>
    );
  }
}
