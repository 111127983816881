import React from 'react';

const LoadingBar = () => {
  return (
    <div className='loading'>
      <div className='loading-bar' />
    </div>
  );
};

export default LoadingBar;
