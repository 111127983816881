import React, { Component } from 'react';
import { Button, Card, CardBody, FormGroup, Input, ListGroup, ListGroupItem, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import LoadingBar from '../Utilities/LoadingBar';
import AuthService from '../Authentication/AuthService';
import EmployeeModal from './EmployeeModal';

export default class Directory extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();

    this.state = {
      activeUser: null,
      filtEmployees: [],
      employees: [],
      displayModal: false
    };
  }

  componentDidMount() {
    this.getEmployees();
  }

  /**
   * @description Retrieves employees from the /directory endpoint
   */
  getEmployees = async () => {
    this.setState({ loading: true });
    try {
      const res = await this.Auth.fetch('/directory', { method: 'GET' });
      res.data.sort((a, b) => {
        return a.lastName < b.lastName ? -1 : 1;
      });
      this.setState({
        employees: res.data,
        filtEmployees: res.data,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Closes the modal
   */
  closeModal = () => {
    this.setState({
      displayModal: false,
      activeUser: null
    });
    this.getEmployees();
  };

  /**
   * @description Updates the current input field
   * @param {Object} event
   */
  updateSearch = (event) => {
    /* eslint-disable */
    const { value } = event.target;
    const employeeList = this.state.employees;
    const filtEmployees = employeeList.filter(item => {
      return item.firstName.toLowerCase().includes(value.toLowerCase()) || item.lastName.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({
      searchTerm: value,
      filtEmployees
    });
  };

  render() {
    const { activeUser, displayModal, searchTerm, filtEmployees, loading } = this.state;

    return (
      <div className="component-container">
        <div className="component-header card">
          {loading && <LoadingBar />} <div className="component-header-text">Employee Management</div>
        </div>
        <div className="component-body">
          <Modal isOpen={displayModal} size="lg">
            <EmployeeModal
              employee={activeUser}
              display={displayModal}
              closeModal={this.closeModal}
              update={this.updateActiveUser}
              add={this.addEmployee}
              save={this.updateEmployee}
              remove={this.deleteEmployee}
            />
          </Modal>
          <Card>
            <CardBody>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Begin typing to find employees..."
                  value={searchTerm}
                  onChange={this.updateSearch}
                />
              </FormGroup>
              <ListGroup>
                <ListGroupItem className="d-flex align-items-center">
                  <Button
                    outline
                    color="success"
                    size="sm"
                    onClick={() => this.setState({ displayModal: true, activeUser: {} })}
                  >
                    Add New Employee
                  </Button>
                </ListGroupItem>
                {filtEmployees.map(item => {
                  return (
                    <ListGroupItem className="d-flex justify-content-between align-items-center" key={item.employeeId}>
                      {`${item.firstName} ${item.lastName}`}
                      <Button
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => this.setState({ displayModal: true, activeUser: { ...item } })}
                      >
                        Edit
                      </Button>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
