import React, { Component } from 'react';
import { Multiselect, DropdownList } from 'react-widgets';
import AuthService from '../Authentication/AuthService';

export default class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();

    this.state = {
      onChange: props.onChange || null,
      includeGroups: props.groups || false,
      includeUsers: props.users || false,
      includeEmployees: props.employees || false,
      multiselect: props.multiselect || false,
      options: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getItems();
  }

  componentWillReceiveProps({ selected, placeholder }) {
    console.log(placeholder);
    this.setState({
      selected,
      placeholder
    });
  }

  /**
   * @description Retrieves items based on props. Will not fail if any request fails
   */
  getItems = async () => {
    const { includeGroups, includeUsers, includeEmployees } = this.state;
    const promises = [];
    if (includeGroups) {
      promises.push(this.getGroups());
    }
    if (includeUsers) {
      promises.push(this.getUsers());
    }
    if (includeEmployees) {
      promises.push(this.getEmployees());
    }
    Promise.all(promises.map(p => p.catch(() => undefined))).then((responses) => {
      let options = [];
      responses.forEach((res) => {
        if (res !== undefined) {
          if (res.message.includes('Groups retrieved')) {
            const groupData = res.data;
            options = options.concat(groupData);
          } else if (res.message.includes('directory')) {
            const empData = res.data.map((emp) => {
              emp.displayName = `${emp.firstName} ${emp.lastName}`;
              emp.displayId = emp.employeeId;
              emp.displayGroup = 'Employees';
              return emp;
            });
            options = options.concat(empData);
          } else if (res.message.includes('Retrieved all users')) {
            const userData = res.data.map((user) => {
              user.displayName = `${user.firstName} ${user.lastName}`;
              user.displayId = user.username;
              user.displayGroup = 'Users';
              return user;
            });
            options = options.concat(userData);
          }
        }
      });
      this.setState({
        options,
        loading: false
      });
    });
  };

  /**
   * @description Retrieves employees from the /directory endpoint
   */
  getEmployees = () => {
    return this.Auth.fetch('/directory', { method: 'GET' });
  };

  getGroups = () => {
    return this.Auth.fetch('/groups', { method: 'GET' });
  };

  getUsers = () => {
    return this.Auth.fetch('/getAllUsers', { method: 'GET' });
  };

  render() {
    const { loading, multiselect, options, onChange, selected, placeholder } = this.state;

    return (
      <>
        {options
          && (multiselect ? (
            <Multiselect
              value={selected}
              data={options || []}
              textField='displayName'
              dataField='displayId'
              onChange={onChange}
              filter='contains'
              busy={loading}
              groupBy='displayGroup'
            />
          ) : (
            <DropdownList
              defaultValue={placeholder}
              value={selected}
              data={options || []}
              textField='displayName'
              dataField='displayId'
              onChange={onChange}
              filter='contains'
              busy={loading}
              groupBy='displayGroup'
            />
          ))}
      </>
    );
  }
}
