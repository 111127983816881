import React, { Component } from 'react';
import { Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane } from 'reactstrap';
import { DateTimePicker, DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import moment from 'moment';
import AuthService from '../Authentication/AuthService';
import config from '../../config';

const { pointAmounts } = config;

const PointItem = ({ item }) => (
  <span>
    <strong>{item.action}</strong>
    {` : ${item.amount}`}
  </span>
);

export default class EmployeeModal extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    this.state = {
      employee: props.employee || {},
      activeTab: '1'
    };
  }

  componentDidMount() {
    const { employee } = this.props;
    if (!employee.employeeId) {
      this.setState({ newEmp: true });
    } else if (employee.points) {
      const totalPoints = this.sumPoints(employee.points);
      this.setState({ totalPoints });
    }
  }

  addEmployee = async () => {
    const { employee } = this.state;
    employee.creationDate = new Date().valueOf();
    try {
      await this.Auth.fetch('/directory', {
        method: 'POST',
        body: JSON.stringify(employee)
      });
      this.closeEmployeeModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  updateEmployee = async () => {
    const { employee } = this.state;
    try {
      await this.Auth.fetch('/directory', {
        method: 'PUT',
        body: JSON.stringify(employee)
      });
      this.closeEmployeeModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  deleteEmployee = async () => {
    const { employee } = this.state;
    const body = JSON.stringify({
      employeeId: employee.employeeId,
      creationDate: employee.creationDate
    });
    try {
      await this.Auth.fetch('/directory', {
        method: 'DELETE',
        body
      });
      this.closeEmployeeModal();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Closes the modal
   */
  closeEmployeeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  addPoints = () => {
    const { employee, newPoints, newPointsDate, newPointsAction } = this.state;
    const pointObj = {
      count: newPoints,
      date: moment(newPointsDate).valueOf(),
      type: newPointsAction
    };
    if (employee.points && employee.points.length) {
      employee.points.push(pointObj);
    } else {
      employee.points = [];
      employee.points.push(pointObj);
    }
    const totalPoints = this.sumPoints(employee.points);
    this.setState({
      employee,
      newPoints: '',
      newPointsAction: null,
      totalPoints
    });
  };

  /**
   * @description Updates the active user state
   * @param {*} event
   */
  updateField = (event) => {
    const { name, value } = event.target;
    const { employee } = this.state;
    employee[name] = value;
    this.setState({ employee });
  };

  sumPoints = (points) => {
    return points.reduce((acc, val) => {
      const currentTotal = parseInt(val.count, 10) + acc;
      return currentTotal;
    }, 0);
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { activeTab, employee, newEmp, newPoints, newPointsDate, newPointsAction, totalPoints } = this.state;

    return (
      <>
        <ModalHeader>{`${employee.firstName || 'Add New Employee'} ${employee.lastName || ''}`}</ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: '1rem' }}>
            <Nav pills>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => { this.toggle('1'); }}
                >
                  <FontAwesomeIcon
                    icon='user'
                  />
                  {' '}
                  Employee Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  disabled={newEmp}
                  onClick={() => { this.toggle('2'); }}
                >
                  <FontAwesomeIcon
                    icon='trophy'
                  />
                  {' '}
                  Olympics Points
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <FormGroup row>
                <Col>
                  <Label for='firstName'>First Name</Label>
                  <Input
                    name='firstName'
                    value={employee.firstName}
                    onChange={this.updateField}
                  />
                </Col>
                <Col>
                  <Label for='lastName'>Last Name</Label>
                  <Input
                    name='lastName'
                    value={employee.lastName}
                    onChange={this.updateField}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Input
                  name='email'
                  type='email'
                  value={employee.email}
                  onChange={this.updateField}
                />
              </FormGroup>
              <FormGroup row>
                <Col sm={9}>
                  <Label for='phone'>Phone</Label>
                  <Input
                    name='phone'
                    value={employee.phone}
                    onChange={this.updateField}
                  />
                </Col>
                <Col sm={3}>
                  <Label for='extension'>Extension</Label>
                  <Input
                    name='extension'
                    value={employee.extension}
                    onChange={this.updateField}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for='securePhone'>Secure Phone</Label>
                <Input
                  name='securePhone'
                  value={employee.securePhone}
                  onChange={this.updateField}
                />
              </FormGroup>
              <FormGroup row>
                <Col sm={9}>
                  <Label for='otherPhone'>Other Phone</Label>
                  <Input
                    name='otherPhone'
                    value={employee.otherPhone}
                    onChange={this.updateField}
                  />
                </Col>
                <Col sm={3}>
                  <Label for='otherPhoneExtension'>Extension</Label>
                  <Input
                    name='otherPhoneExtension'
                    value={employee.otherPhoneExtension}
                    onChange={this.updateField}
                  />
                </Col>
              </FormGroup>
              <ModalFooter>
                {!newEmp && (
                  <Button
                    color='danger'
                    onClick={this.deleteEmployee}
                  >
                    Delete
                  </Button>
                )}
                {newEmp && (
                  <Button
                    color='primary'
                    onClick={() => {
                      this.addEmployee();
                    }}
                  >
                    Add
                  </Button>
                )}
                {!newEmp && (
                  <Button
                    color='primary'
                    onClick={() => {
                      this.updateEmployee();
                    }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  color='secondary'
                  onClick={() => {
                    this.closeEmployeeModal();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </TabPane>
            <TabPane tabId='2'>
              <FormGroup>
                <ListGroup>
                  <ListGroupItem>
                    <Row>
                      <Col sm={3}>
                        Date
                      </Col>
                      <Col sm={3}>
                        # Points
                      </Col>
                      <Col sm={3}>
                        Action
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={3}>
                        <DateTimePicker
                          time={false}
                          value={newPointsDate}
                          onChange={date => this.setState({ newPointsDate: date })}
                        />
                      </Col>
                      <Col sm={3}>
                        <FormGroup row>
                          <Col sm={10}>
                            <Input
                              type='number'
                              value={newPoints}
                              onChange={e => this.setState({ newPoints: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup row>
                          <Col sm={9}>
                            <DropdownList
                              data={pointAmounts}
                              value={newPointsAction}
                              itemComponent={PointItem}
                              textField='action'
                              valueField='action'
                              onChange={val =>
                                this.setState({
                                  newPointsAction: val.action,
                                  newPoints: val.amount
                                })
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col
                        sm={2}
                        className='d-flex align-items-center'
                      >
                        <FormGroup>
                          <Button
                            disabled={!newPoints || !newPointsAction}
                            color='success'
                            size='sm'
                            onClick={this.addPoints}
                          >
                            Add Points
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroup
                    style={{
                      maxHeight: 246,
                      overflowY: 'auto'
                    }}
                  >
                    {employee.points
                      && employee.points.sort((a, b) => {
                          return parseInt(a.date, 10) > parseInt(b.date, 10) ? -1 : 1;
                        })
                        .map((entry, idx) => {
                          const key = entry.date + idx;
                          return (
                            <ListGroupItem key={key}>
                              <Row>
                                <Col>
                                  <strong>{entry.count}</strong>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                  {moment.utc(parseInt(entry.date, 10)).format('ll')}
                                  {' - '}
                                  {entry.type}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        })}
                  </ListGroup>
                  <ListGroupItem>
                    Total Points:
                    <strong>{` ${totalPoints || 0}`}</strong>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
              <ModalFooter>
                <Button
                  color='primary'
                  onClick={() => {
                    this.updateEmployee();
                  }}
                >
                  Save
                </Button>
                <Button
                  color='secondary'
                  onClick={() => {
                    this.closeEmployeeModal();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </TabPane>
          </TabContent>
        </ModalBody>
      </>
    );
  }
}
