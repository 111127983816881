import React, { Component } from 'react';
import { Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import AuthService from '../Authentication/AuthService';
import LoadingBar from '../Utilities/LoadingBar';
import UserSearch from '../Utilities/UserSearch';

export default class GroupManagement extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    this.state = {};
  }

  forceRefresh = () => {
    this.setState({ forceRefresh: new Date().valueOf() });
  };

  getGroupMembership = async (group) => {
    this.setState({ loading: true });
    try {
      const req = { group: group.displayName };
      const res = await this.Auth.fetch('/usersInGroup',
      {
        method: 'POST',
        body: JSON.stringify(req)
      });
      this.setState({ activeGroupMembership: res.data });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Handles the selection of a group from the main group search
   * @param {Object} group
   */
  handleGroupSelected = (group) => {
    this.setState({ activeGroup: group });
    this.getGroupMembership(group);
  };

  createGroup = async () => {
    this.setState({ loading: true });
    const { newGroupName, newGroupDesc } = this.state;
    const reqBody = {
      groupName: newGroupName,
      groupDesc: newGroupDesc
    };
    try {
      await this.Auth.fetch('/groups', {
        method: 'PUT',
        body: JSON.stringify(reqBody)
      });
      this.setState({
        newGroupName: '',
        newGroupDesc: ''
      });
      this.setState({ loading: false });
      toast.success('Group successfully created');
      this.forceRefresh();
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  updateGroup = async () => {
    this.setState({ loading: true });
    const { newGroupName, newGroupDesc } = this.state;
    const reqBody = {
      name: newGroupName,
      description: newGroupDesc
    };
    try {
      await this.Auth.fetch('/groups', {
        method: 'POST',
        body: JSON.stringify(reqBody)
      });
      this.setState({ loading: false });
      this.setState({ newGroupDesc: '' });
      toast.success('Group successfully updated');
      this.forceRefresh();
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  deleteGroup = async (groupName) => {
    this.setState({ loading: true });
    const reqBody = { groupName };
    try {
      await this.Auth.fetch('/groups', {
        method: 'DELETE',
        body: JSON.stringify(reqBody)
      });
      this.setState({ loading: false });
      toast.success('Group successfully deleted');
      this.setState({ activeGroup: undefined });
      this.forceRefresh();
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Adds a specific user to a group
   * @param {Object} user
   * @param {Object} group
   */
  addUserToGroup = async (user, group) => {
    this.setState({ loading: true });
    const reqBody = {
      user: user.username,
      group: group.displayName
    };
    try {
      await this.Auth.fetch('/userGroup', {
        method: 'PUT',
        body: JSON.stringify(reqBody)
      });
      this.setState({ loading: false });
      toast.success('User successfully added to group');
      this.handleGroupSelected(group);
      this.forceRefresh();
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Removes a user from a group
   * @param {Object} user
   * @param {Object} group
   */
  removeUserFromGroup = async (user, group) => {
    this.setState({ loading: true });
    const reqBody = {
      user: user.username,
      group: group.displayName
    };
    try {
      await this.Auth.fetch('/userGroup', {
        method: 'DELETE',
        body: JSON.stringify(reqBody)
      });
      this.setState({ loading: false });
      toast.success('User successfully removed from group');
      this.handleGroupSelected(group);
      this.forceRefresh();
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  render() {
    const { activeGroup, activeGroupMembership, forceRefresh, loading, newGroupName, newGroupDesc } = this.state;
    return (
      <div className='component-container'>
        <div className='component-header card'>
          <div className='component-header-text'>Group Management</div>
          {loading && <LoadingBar />}
        </div>
        <div className='component-body'>
          <Card>
            <CardBody>
              <CardTitle tag='h3'>Groups</CardTitle>
              <FormGroup>
                <UserSearch
                  groups
                  key={forceRefresh}
                  defaultValue={activeGroup || null}
                  onChange={(selected) => {
                    this.handleGroupSelected(selected);
                  }}
                />
              </FormGroup>
              {activeGroup && (
                <Card key={forceRefresh}>
                  <CardBody>
                    <h4>
                      <>
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                        />
                        <Button
                          color='danger'
                          size='sm'
                          data-tip='Delete group'
                          className='mr-1'
                          onClick={() => {
                            this.deleteGroup(activeGroup.displayName);
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </>
                      {activeGroup.displayName}
                      <Badge
                        color='secondary'
                        className='ml-1'
                      >
                        {activeGroup.groupDescription}
                      </Badge>
                    </h4>
                    <ListGroup>
                      <ListGroupItem>
                        <strong>Members</strong>
                      </ListGroupItem>
                      {activeGroupMembership && activeGroupMembership.length === 0 && <ListGroupItem>No group members</ListGroupItem>}
                      {activeGroupMembership && activeGroupMembership.map((user) => {
                        return (
                          <ListGroupItem
                            key={user.username}
                            className='d-flex justify-content-between align-items-center py-1 px-2'
                          >
                            {`${user.firstName} ${user.lastName}`}
                            <Button
                              outline
                              color='danger'
                              size='sm'
                              onClick={() => this.removeUserFromGroup(user, activeGroup)}
                            >
                              Remove
                            </Button>
                          </ListGroupItem>
                        );
                      })}
                      <ListGroupItem className='d-flex justify-content-center align-items-center'>
                        <InputGroup>
                          <InputGroupAddon addonType='append'>Add User</InputGroupAddon>
                          <UserSearch
                            users
                            key={forceRefresh}
                            onChange={(selected) => {
                              this.addUserToGroup(selected, activeGroup);
                            }}
                          />
                        </InputGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardBody>
                  <FormGroup>
                    <h4 className='mt-2'>Create New Group</h4>
                  </FormGroup>
                  <FormGroup>
                    <Label for='groupName'>Name</Label>
                    <Input
                      name='groupName'
                      value={newGroupName || ''}
                      onChange={(e) => {
                        this.setState({ newGroupName: e.target.value });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='groupName'>Description</Label>
                    <Input
                      name='groupName'
                      value={newGroupDesc || ''}
                      onChange={(e) => {
                        this.setState({ newGroupDesc: e.target.value });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color='success'
                      onClick={this.createGroup}
                    >
                      Create Group
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
