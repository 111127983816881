import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '/Northstrat_App_Privacy_Policy.pdf'
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    console.log(this.state);
    const { file } = this.state;

    return (
      <div
        style={{
          backgroundColor: 'white',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Document
          file={file}
          onLoadError={console.error}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={1} />
        </Document>
      </div>
    );
  }
}
