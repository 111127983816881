import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, CardTitle, Container, Form, FormGroup, Label, Input } from 'reactstrap';
import AuthService from './AuthService';
import LoadingIcon from '../Utilities/LoadingIcon';

export default class Confirm extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();

    this.state = {
      email: '',
      code: '',
      loading: false
    };
  }

  componentWillMount() {
    const { location } = this.props;
    if (location && location.state) {
      const { email } = location.state;
      if (email) {
        this.setState({ email });
      }
    }
    if (this.Auth.loggedIn()) {
      const { history } = this.props;
      history.push('/home');
    }
  }

  /**
   * @description Submits the form
   * @param {SyntheticEvent} e
   */
  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { email, code } = this.state;
    try {
      await this.Auth.confirm(email.toLowerCase(), code);
      this.setState({ loading: false });
      toast.success('Your email address has been confirmed.');
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
      this.setState({
        email: '',
        code: '',
        loading: false
      });
    }
  };

  /**
   * @description Navigates to the log in form
   */
  goToLogin = () => {
    const { history } = this.props;
    history.push('/signin');
  };

  render() {
    const { errorMessage, email, code, loading } = this.state;
    return (
      <Container>
        <Card className='centered-card'>
          {loading && <LoadingIcon size='md' />}
          <CardBody>
            <CardTitle
              className='text-center'
              tag='h3'
            >
              Confirm Email
            </CardTitle>
            <Form onSubmit={this.submitForm}>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  onChange={e => this.setState({ email: e.target.value })}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label for='code'>Confirmation Code</Label>
                <Input
                  type='text'
                  name='code'
                  id='code'
                  onChange={e => this.setState({ code: e.target.value })}
                  value={code}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  color='primary'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  color='secondary'
                  onClick={this.goToLogin}
                >
                  Back to Login
                </Button>
              </FormGroup>
            </Form>
            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
          </CardBody>
        </Card>
      </Container>
    );
  }
}
