import React, { useState, useEffect } from 'react';
import { Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../Utilities/LoadingBar';
import AuthService from '../Authentication/AuthService';
import UseWindowDimensions from '../Utilities/GetWindowDimensions';

function UserManagement() {
  const { height } = UseWindowDimensions();
  const Auth = new AuthService();

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [needsRefresh, refreshUsers] = useState(true);
  const [activeUser, setActiveUser] = useState({});
  const [newUser, setNewUser] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [showEnableModal, toggleEnableModal] = useState(false);
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [showUpdateModal, toggleUpdateModal] = useState(false);
  const [showCreateModal, toggleCreateModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    /**
     * @description Retrieves users from the Cognito UserPool
     */
    const getUsers = async () => {
      setLoading(true);
      try {
        const res = await Auth.fetch('/getAllUsers', { method: 'GET' });
        const allUsers = res.data;
        allUsers.sort((a, b) => {
          return a.lastName < b.lastName ? -1 : 1;
        });
        setUsers(allUsers);
        setFilteredUsers(allUsers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.toString(), { autoClose: false });
      }
    };
    if (needsRefresh) {
      getUsers();
      refreshUsers(false);
    }
  }, [needsRefresh, Auth]);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) => {
        return user.firstName && user.lastName ? user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
          || user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) : true;
      })
    );
  }, [searchTerm, users]);

  const toggleUserEnabled = async (user) => {
    setLoading(true);
    const endpoint = user.enabled ? '/disableUser' : '/enableUser';
    const reqBody = {
      username: user.username,
      email: user.email
    };
    try {
      const res = await Auth.fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(reqBody)
      });
      if (res) {
        refreshUsers(true);
        toast.success(res.message);
        refreshUsers(false);
      }
    } catch (error) {
      toast.error(`Error ${user.enabled ? 'disabling' : 'enabling'} account for ${user.email}`, { autoClose: false });
    }
  };

  const deleteUser = async (user) => {
    const reqBody = {
      username: user.username,
      email: user.email
    };
    try {
      const res = await Auth.fetch('/userAccount', {
        method: 'DELETE',
        body: JSON.stringify(reqBody)
      });
      if (res) {
        refreshUsers(true);
        toast.success(res.message);
        refreshUsers(false);
      }
    } catch (error) {
      toast.error(`Error deleting account for: ${user.email}`, { autoClose: false });
    }
  };

  const createUser = async (user) => {
    try {
      const res = await Auth.fetch('/userAccount', {
        method: 'PUT',
        body: JSON.stringify(user)
      });
      if (res) {
        refreshUsers(true);
        toast.success(res.message);
        setNewUser({});
        refreshUsers(false);
      }
    } catch (error) {
      toast.error(`Error creating account for: ${user.email}`, { autoClose: false });
    }
  };

  const updateUser = async (user) => {
    const attributes = [
      {
        Name: 'given_name',
        Value: user.firstName
      },
      {
        Name: 'family_name',
        Value: user.lastName
      },
      {
        Name: 'email',
        Value: user.email
      }
    ];
    const reqBody = {
      username: user.username,
      email: user.email,
      attributes
    };
    try {
      const res = await Auth.fetch('/userAccount', {
        method: 'POST',
        body: JSON.stringify(reqBody)
      });
      if (res) {
        refreshUsers(true);
        toast.success(res.message);
        setNewUser({});
        refreshUsers(false);
      }
    } catch (error) {
      toast.error(`Error updating account for: ${user.email}`, { autoClose: false });
    }
  };

  return (
    <div className='component-container'>
      <div className='component-header card'>
        {loading && <LoadingBar />}
        <div className='component-header-text'>User Account Management</div>
      </div>
      <div
        className='component-body'
        style={{ maxHeight: height - 150 }}
      >
        <Card>
          <CardBody>
            <FormGroup>
              <Input
                type='text'
                placeholder='Begin typing to find users...'
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </FormGroup>
            {/* <ListGroup>
              <ListGroupItem style={{ textAlign: 'center' }}>
                <Button
                  data-tip='Disabled until back-end workflow is finished'
                  disabled
                  color='success'
                  size='sm'
                  onClick={() => {
                    toggleCreateModal(true);
                  }}>
                  <FontAwesomeIcon
                    icon='user-plus'
                    className='fa-hover'
                  />
                  {' '}
                  Create New User
                </Button>
              </ListGroupItem>
            </ListGroup> */}
            <ListGroup style={{
              height: height - 300,
              overflowY: 'auto'
            }}
            >
              {filteredUsers.map((user) => {
              return (
                <ListGroupItem
                  className='d-flex justify-content-between align-items-center'
                  key={user.username}
                >
                  <div className='list-group-col'>
                    {`${user.firstName} ${user.lastName}`}
                  </div>
                  <div className='list-group-col'>
                    {`${user.email}`}
                  </div>
                  <div className='list-group-col'>
                    <Button
                      outline
                      color='primary'
                      size='sm'
                      onClick={() => {
                        setUpdatedUser({ ...user });
                        toggleUpdateModal(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon='user-edit'
                        className='fa-hover'
                      />
                      {' '}
                      Update User
                    </Button>
                  </div>
                  <div className='list-group-col'>
                    <Button
                      outline
                      color='warning'
                      size='sm'
                      onClick={() => {
                        setActiveUser({ ...user });
                        toggleEnableModal(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={user.enabled ? 'lock' : 'lock-open'}
                        className='fa-hover'
                      />
                      {' '}
                      {user.enabled ? 'Disable User' : 'Enable User'}
                    </Button>
                  </div>
                  <div className='list-group-col'>
                    <Button
                      outline
                      color='danger'
                      size='sm'
                      onClick={() => {
                        setActiveUser({ ...user });
                        toggleDeleteModal(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon='trash'
                        className='fa-hover'
                      />
                      {' '}
                      Delete User
                    </Button>
                  </div>
                </ListGroupItem>
            );
            })}
            </ListGroup>
            <ListGroup>
              {users && users.length > 0 && (
                <ListGroupItem>
                  {`Total Registered Users:  ${users.length}`}
                </ListGroupItem>
              )}
            </ListGroup>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={showEnableModal}
        backdrop='true'
      >
        <ModalHeader>
          {activeUser.enabled ? 'Disable' : 'Enable'}
          {' '}
          Account
        </ModalHeader>
        <ModalBody>
          {activeUser.enabled ? 'Disable' : 'Enable'}
           account for 
          {activeUser.email}
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              toggleEnableModal(false);
              toggleUserEnabled(activeUser);
            }}
          >
            OK
          </Button>
          {' '}
          <Button
            color='secondary'
            onClick={() => {
              setActiveUser({});
              toggleEnableModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showDeleteModal}
        backdrop='true'
      >
        <ModalHeader>Delete Account</ModalHeader>
        <ModalBody>
          Delete user account for 
          {activeUser.email}
          ?
          <br />
          <FontAwesomeIcon
            icon='exclamation-triangle'
            className='fa-hover'
          />
          {' '}
          This action CAN NOT be undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              toggleDeleteModal(false);
              deleteUser(activeUser);
            }}
          >
            OK
          </Button>
          {' '}
          <Button
            color='secondary'
            onClick={() => {
              setActiveUser({});
              toggleDeleteModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showUpdateModal}
        backdrop='true'
      >
        <ModalHeader>Update User</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup row>
              <Col>
                <Label for='firstName'>First Name</Label>
                <Input
                  name='firstName'
                  value={updatedUser.firstName}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      firstName: e.target.value
                    }); 
                  }}
                />
              </Col>
              <Col>
                <Label for='lastName'>Last Name</Label>
                <Input
                  name='lastName'
                  value={updatedUser.lastName}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      lastName: e.target.value
                    }); 
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input
                name='email'
                type='email'
                value={updatedUser.email}
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    email: e.target.value
                  }); 
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                updateUser(updatedUser);
                toggleUpdateModal(false);
              }}
            >
              Update
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                toggleUpdateModal(false);
                setUpdatedUser({});
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        isOpen={showCreateModal}
        backdrop='true'
      >
        <ModalHeader>Create New User Account</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup row>
              <Col>
                <Label for='firstName'>First Name</Label>
                <Input
                  name='firstName'
                  value={newUser.firstName}
                  onChange={(e) => { newUser.firstName = e.target.value; }}
                />
              </Col>
              <Col>
                <Label for='lastName'>Last Name</Label>
                <Input
                  name='lastName'
                  value={newUser.lastName}
                  onChange={(e) => { newUser.lastName = e.target.value; }}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input
                name='email'
                type='email'
                value={newUser.email}
                onChange={(e) => { newUser.email = e.target.value; }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                createUser(newUser);
                toggleCreateModal(false);
              }}
            >
              Create
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                toggleCreateModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default UserManagement;
