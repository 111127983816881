import React, { Component, useCallback } from 'react';
import { Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row } from 'reactstrap';
import Moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { DateTimePicker, DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import LoadingBar from '../Utilities/LoadingBar';
import appConfig from '../../config';
import AuthService from '../Authentication/AuthService';

const IMAGE_URL = appConfig.s3.MEDIABUCKET;

const FileUpload = (props) => {
  const { files, addFiles, removeFiles } = props;
  const onDrop = useCallback(selectedfiles => addFiles(selectedfiles), [addFiles]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpg', 'image/jpeg', 'image/png']
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Alert color='info'>
        <p>Drag image files here, or click to select files</p>
        <small>(Only .jpg, .jpeg, and .png images will be accepted)</small>
        <ListGroup>
          {files
            && files.map((file, idx) => {
              return (
                <ListGroupItem key={file.lastModified}>
                  {file.name}
                  <FontAwesomeIcon
                    icon='trash'
                    className='fa-append fa-hover'
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFiles(idx);
                    }}
                  />
                </ListGroupItem>
              );
            })}
        </ListGroup>
      </Alert>
    </div>
  );
};

const ImageCard = (props) => {
  const { image, deleteFunc } = props;
  return (
    <Col sm='3'>
      <Card>
        <ReactTooltip
          place='right'
          type='dark'
          effect='solid'
        />
        <FontAwesomeIcon
          icon='trash'
          className='text-danger fa-hover'
          style={{
            left: 5,
            position: 'absolute',
            top: 5
          }}
          onClick={() => deleteFunc(image.fileId, image.uploadDate)}
          data-tip='Delete File'
        />
        <CardImg
          top
          width='100%'
          src={`${IMAGE_URL}/${image.fileId}.jpg`} //
          alt={image.fileId}
        />
        <CardBody
          style={{
            fontSize: '.85rem',
            padding: '.5rem'
          }}
        >
          <CardTitle>
            <FontAwesomeIcon
              icon='user'
              className='fa-prepend'
            />
            {image.uploader}
          </CardTitle>
          <CardTitle>
            <FontAwesomeIcon
              icon='calendar-alt'
              className='fa-prepend'
            />
            {Moment(image.captureDate).format('ll')}
          </CardTitle>
          {image.eventTitle && (
            <CardTitle>
              <FontAwesomeIcon
                icon='glass-cheers'
                className='fa-prepend'
              />
              {image.eventTitle}
            </CardTitle>
          )}
          {image.comments && (
            <CardTitle>
              <FontAwesomeIcon
                icon='comments'
                className='fa-prepend'
              />
              {image.comments}
            </CardTitle>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default class Media extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    this.state = {
      events: [],
      files: null,
      search: null,
      comments: null
    };
  }

  componentDidMount() {
    this.getMedia();
    this.getEvents();
  }

  /**
   * @description Retrieves all image metadata from the database
   */
  getMedia = async () => {
    this.setState({ loading: true });
    try {
      const res = await this.Auth.fetch('/media', { method: 'GET' });
      this.setState({
        images: res.data,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Retrieves events from the /events endpoint
   */
  getEvents = async () => {
    this.setState({ loading: true });
    try {
      const res = await this.Auth.fetch('/calendar', { method: 'GET' });
      this.setState({
        events: res.data,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
        toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Uploads the images to the bucket and database
   */
  startUploads = async () => {
    this.setState({ loading: true });
    const { files, event, comments, date } = this.state;
    const profile = this.Auth.getProfile();
    const now = Moment.utc().valueOf();
    const metadata = {
      captureDate: event ? event.start : date || now,
      comments,
      eventId: event ? event.eventId : null,
      eventTitle: event ? event.title : null,
      uploader: `${profile.given_name} ${profile.family_name}`,
      uploadDate: now,
      uploaderId: profile['cognito:username']
    };
    const uploadPromises = files.map((file) => {
      return this.uploadFile(file, metadata);
    }, this);

    try {
      await Promise.all(uploadPromises);
      setTimeout(() => {
        this.getMedia();
        toast.success('Images successfully uploaded', { autoClose: 3000 });
        this.setState({ files: [] });
      }, 500);
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Uploads the file to the database, returns a promise
   * @param {File} file
   * @param {Object} metadata
   * @return {Promise}
   */
  uploadFile = (file, metadata) => {
    const fr = new FileReader();
    fr.onload = () => {
      const base64 = fr.result;
      const body = {
        image: base64,
        metadata
      };
      return this.Auth.fetch('/media', {
        method: 'POST',
        body: JSON.stringify(body)
      });
    };
    fr.readAsDataURL(file);
  };

  /**
   * @description Removes the file from the bucket and database
   */
  deleteFile = async (fileId, uploadDate) => {
    this.setState({ loading: true });
    const body = JSON.stringify({
      fileId,
      uploadDate
    });

    try {
      await this.Auth.fetch('/media', {
        method: 'DELETE',
        body
      });
      this.getMedia();
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Adds the selected files to the upload queue
   */
  addFilesToList = (files) => {
    this.setState({ files });
  };

  /**
   * @description Removes the selected files from the upload queue
   */
  removeFileFromList = (idx) => {
    const { files } = this.state;
    files.splice(idx, 1);
    this.setState({ files });
  };

  render() {
    const { comments, date, events, files, images, event, search, loading } = this.state;
    return (
      <div className='component-container'>
        <div className='component-header card'>
          {loading && <LoadingBar />} 
          {' '}
          <div className='component-header-text'>Media Gallery</div>
        </div>
        <div className='component-body'>
          <Card>
            <CardBody>
              <FileUpload
                files={files}
                addFiles={this.addFilesToList}
                removeFiles={this.removeFileFromList}
              />
              <Container fluid>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for='event'>Add Images to Event</Label>
                      <DropdownList
                        data={events}
                        textField={(item) => {
                          if (item) {
                            return `${item.title} - ${Moment(item.start).format('ll')}`;
                          }
                          return '';
                        }}
                        onChange={(val) => {
                          this.setState({ event: val });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for='event'>Capture Date</Label>
                      <DateTimePicker
                        time={false}
                        onChange={(val) => {
                          this.setState({ date: val });
                        }}
                        value={date || (event && new Date(event.start))}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for='event'>Comments</Label>
                      <Input
                        type='text'
                        onChange={(e) => {
                          this.setState({ comments: e.target.value });
                        }}
                        value={comments || ''}
                      />
                      <FormText color='muted'>Comments will be applied to all files in the upload</FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <FormGroup>
                    <Button
                      color='primary'
                      size='lg'
                      disabled={!files || !files.length}
                      onClick={this.startUploads}
                    >
                      Upload
                    </Button>
                  </FormGroup>
                </Row>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Type to filter by name, event, or comments...'
                    onChange={e => this.setState({ search: e.target.value })}
                    value={search || ''}
                  />
                </FormGroup>
                <Row
                  style={{
                    minHeight: 200,
                    overflowY: 'auto',
                    position: 'relative'
                  }}
                >
                  {images
                    && images.map((image) => {
                      const commentHit = (image.comments && image.comments.toLowerCase().includes(search && search.toLowerCase()))
                        || false;
                      const uploaderHit = (image.uploader && image.uploader.toLowerCase().includes(search && search.toLowerCase()))
                        || false;
                      const eventHit = (image.eventTitle && image.eventTitle.toLowerCase().includes(search && search.toLowerCase()))
                        || false;
                      if (!search || (commentHit || uploaderHit || eventHit)) {
                        return (
                          <ImageCard
                            image={image}
                            key={image.fileId}
                            deleteFunc={this.deleteFile}
                          />
);
                      }
                      return '';
                    })}
                </Row>
              </Container>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
