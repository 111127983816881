import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Moment from 'moment';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import { DropdownList } from 'react-widgets';
import LoadingBar from '../Utilities/LoadingBar';
import UserSearch from '../Utilities/UserSearch';
import AuthService from '../Authentication/AuthService';
import config from '../../config';

const quillConfig = {
  formats: [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ],
  modules: {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: { matchVisual: false }
  }
};

const { notificationTypes } = config;

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      title: '',
      subtitle: '',
      content: '',
      notificationType: 'Announcement'
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents = async () => {
    this.setState({ loading: true });
    try {
      const res = await this.Auth.fetch('/calendar', { method: 'GET' });
      this.setState({
        events: res.data,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Submits the form
   */
  submitForm = async () => {
    this.setState({ loading: true });
    const { notificationType, title, recipients, event } = this.state;
    let { content, subtitle } = this.state;
    const type = notificationType || 'Newsletter';
    const creationUser = this.Auth.getUserId();
    const headline = title || 'Test';
    const audience = recipients;

    if (!content || content.length === 0) {
      content = subtitle.length ? subtitle : 'No content provided';
    }

    if (subtitle.length === 0) {
      subtitle = 'No subtitle provided';
    }

    try {
      await this.Auth.fetch('/notification', {
        method: 'POST',
        body: JSON.stringify({
          type,
          creationUser,
          headline,
          subtitle,
          content: content || '',
          audience,
          recipients,
          event
        })
      });
      toast.success('Message sent successfully', { autoClose: 3000 });
        this.setState({ loading: false });
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
        this.setState({ loading: false });
    }
  };

  render() {
    const { events, notificationType, title, subtitle, content, loading, recipients } = this.state;
    return (
      <div className='component-container'>
        <div className='component-header card'>
          <div className='component-header-text'>Message Center</div>
          {loading && <LoadingBar />}
        </div>
        <div className='component-body'>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <DropdownList
                    data={notificationTypes}
                    defaultValue='Announcement'
                    placeholder='Select message type'
                    onChange={value => this.setState({
                      notificationType: value,
                      content: ''
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='title'>Title</Label>
                  <Input
                    placeholder='This will be used for the mobile app notification title as well....'
                    type='text'
                    name='title'
                    id='title'
                    onChange={e => this.setState({ title: e.target.value })}
                    value={title || ''}
                  />
                </FormGroup>
                {notificationType !== 'Newsletter' && (
                  <FormGroup>
                    <Label for='subtitle'>Notification Body</Label>
                    <Input
                      placeholder='This will be the body of the mobile app notification....'
                      type='text'
                      name='subtitle'
                      id='subtitle'
                      onChange={e => this.setState({ subtitle: e.target.value })}
                      value={subtitle || ''}
                    />
                  </FormGroup>
                )}
                {notificationType === 'Newsletter' && (
                  <FormGroup
                    style={{
                      height: 200,
                      marginBottom: '6rem'
                    }}
                  >
                    <Label for='content'>Content</Label>
                    <ReactQuill
                      formats={quillConfig.formats}
                      modules={quillConfig.modules}
                      onChange={html => this.setState({ content: html })}
                      value={content}
                      style={{ height: '100%' }}
                    />
                  </FormGroup>
                )}
                {notificationType === 'Event Reminder' && (
                  <FormGroup>
                    <Label for='event'>Event</Label>
                    <DropdownList
                      data={events}
                      textField={(item) => {
                        if (item) {
                          return `${item.title} - ${Moment(item.start).format('ll')}`;
                        }
                        return '';
                      }}
                      onChange={(val) => {
                        this.setState({ event: val });
                      }}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for='UserSearch'>Recipients</Label>
                  <UserSearch
                    selected={recipients}
                    groups
                    onChange={selection => this.setState({ recipients: selection.displayName })}
                  />
                </FormGroup>
                <Button
                  color='primary'
                  onClick={this.submitForm}
                  disabled={loading || !notificationType || !title || !subtitle || !recipients}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
