import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Form, FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import AuthService from './AuthService';
import LoadingIcon from '../Utilities/LoadingIcon';

const SuccessMessage = (props) => {
  const { email } = props;
  return (
    <>
      <p>An email with further instructions has been sent to the provided address.</p>
      <p>
        <Link
          to={{
            pathname: '/confirm',
            state: { email }
          }}
        >
          Click here to enter the confirmation code provided
        </Link>
      </p>
    </>
  );
};

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();

    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      formErrors: {
        email: '',
        password: '',
        firstName: '',
        lastName: ''
      },
      emailValid: true,
      passwordValid: true,
      firstNameValid: true,
      lastNameValid: true,
      formValid: true,
      loading: false
    };
  }

  componentWillMount() {
    if (this.Auth.loggedIn()) {
      const { history } = this.props;
      history.push('/home');
    }
  }

  /**
   * @description Submits the form
   * @param {SyntheticEvent} e
   */
  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { email, password, firstName, lastName } = this.state;
    try {
      await this.Auth.signUp(email.toLowerCase(), password, firstName, lastName);
      this.setState({
        loading: false,
        email: '',
        password: '',
        firstName: '',
        lastName: ''
      });
      toast.success(<SuccessMessage email={email} />);
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
      this.setState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        loading: false
      });
    }
  };

  /**
   * @description Updates the current input field
   * @param {Object} event
   */
  updateField = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  /**
   * @description Validates the given field
   * @param {string} fieldName
   * @param {string} value
   */
  validateField = (fieldName, value) => {
    const { formErrors } = this.state;
    let { emailValid, passwordValid, firstNameValid, lastNameValid } = this.state;
    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@(northstrat.com)$/i) && value.length > 1;
        formErrors.email = emailValid ? '' : 'Please enter a valid Northstrat.com email address.';
        break;
      case 'password':
        formErrors.passwordLength = value.length < 8;
        formErrors.passwordLowerCase = !value.match(/[a-z]/g);
        formErrors.passwordUppercase = !value.match(/[A-Z]/g);
        formErrors.passwordNumber = !value.match(/\d/g);
        formErrors.passwordSpecial = !value.match(/[!@#$%^&*(),.?":{}|<>]/g);
        passwordValid = !formErrors.passwordLength
          && !formErrors.passwordLowerCase
          && !formErrors.passwordUppercase
          && !formErrors.passwordNumber
          && !formErrors.passwordSpecial;
        break;
      case 'firstName':
        firstNameValid = value.length > 0;
        break;
      case 'lastName':
        lastNameValid = value.length > 0;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors,
        emailValid,
        passwordValid,
        firstNameValid,
        lastNameValid
      },
      this.validateForm
    );
  };

  /**
   * @description Validates the form in order to enable the submit button
   */
  validateForm = () => {
    const { emailValid, passwordValid, password, firstNameValid, lastNameValid } = this.state;
    this.setState({ formValid: emailValid && passwordValid && password.length > 0 && firstNameValid && lastNameValid });
  };

  render() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      formErrors,
      formValid,
      loading
    } = this.state;
    return (
      <Card className='centered-card'>
        {loading && <LoadingIcon size='md' />}
        <CardBody>
          <CardTitle
            className='text-center'
            tag='h3'
          >
            Sign Up
          </CardTitle>
          <Form onSubmit={this.submitForm}>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input
                type='email'
                name='email'
                id='email'
                onChange={this.updateField}
                value={email}
                invalid={!emailValid}
                
              />
              <FormFeedback>{formErrors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <Input
                type='password'
                name='password'
                id='password'
                onChange={this.updateField}
                value={password}
                invalid={!passwordValid}
              />
              {!passwordValid && (
                <FormFeedback>
                  {`Passwords must contain at least:
                ${formErrors.passwordLength ? '8 characters;' : ''}
                ${formErrors.passwordLowerCase ? 'one lowercase letter;' : ''}
                ${formErrors.passwordUppercase ? 'one uppercase letter;' : ''}
                ${formErrors.passwordNumber ? 'one number digit;' : ''}
                ${formErrors.passwordSpecial ? 'one special character;' : ''}
                `}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for='firstName'>First Name</Label>
              <Input
                type='text'
                name='firstName'
                id='firstName'
                onChange={this.updateField}
                value={firstName}
                invalid={!firstNameValid}
              />
              <FormFeedback>{formErrors.firstName}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for='lastName'>Last Name</Label>
              <Input
                type='text'
                name='lastName'
                id='lastName'
                onChange={this.updateField}
                value={lastName}
                invalid={!lastNameValid}
              />
              <FormFeedback>{formErrors.lastName}</FormFeedback>
            </FormGroup>
            <Button
              color='primary'
              type='submit'
              disabled={!formValid}
            >
              Sign Up
            </Button>
            <Button color='secondary'>
              <Link to='/signin'>Back to Login</Link>
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
