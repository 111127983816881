import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import AuthService from '../Authentication/AuthService';
import logo from '../../images/ns-logo-white.png';

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();

    this.state = {
      navList: [
        {
          title: 'Message Center',
          link: '/messages',
          tooltip: 'Send notifications to individual employees or entire groups'
        },
        {
          title: 'User Management',
          link: '/usermgmt',
          tooltip: 'Manage user accounts'
        },
        {
          title: 'Olympics Management',
          link: '/olympics',
          tooltip: 'Manage Northstrat Olympics'
        },
        {
          title: 'Group Management',
          link: '/groupmgmt',
          tooltip: 'Create/delete groups and add/remove users to/from groups'
        },
        {
          title: 'Directory Management',
          link: '/directory',
          tooltip: 'Manage employee directory information such as contact information and Olympic point accrual'
        },
        {
          title: 'Event Calendar',
          link: '/calendar',
          tooltip: 'Add, edit, or remove events from the calendar'
        },
        {
          title: 'Media Gallery',
          link: '/media',
          tooltip: 'Upload new images to be display in the media gallery'
        }
      ]
    };
  }

  handleSignOut = () => {
    this.Auth.signout();
    const { signout } = this.props;
    signout();
  };

  render() {
    const { navList } = this.state;
    return (
      <div className='app-nav'>
        <ReactTooltip
          id='nav-global-tooltips'
          place='left'
          type='dark'
          effect='solid'
        />
        <div className='logo'>
          <img
            src={logo}
            width='auto'
            height='65'
            alt=''
          />          
        </div>
        <Nav vertical>
          {navList.map((item) => {
            const active = window.location.href.includes(item.link);
            return (
              <NavItem
                key={item.title}
                className={`${active ? 'active' : ''}`}
              >
                
                <Link
                  to={item.link}
                  className='nav-link'
                  data-tip={item.tooltip}
                  data-for='nav-global-tooltips'
                  data-delay-show='1000'
                >
                  {item.title}
                </Link>
              </NavItem>
            );
          })}
          <NavItem>
            <NavLink onClick={this.handleSignOut}>
              <FontAwesomeIcon
                icon='sign-out-alt'
                data-tip='Sign-out'
                data-for='nav-global-tooltips'
              />
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}
