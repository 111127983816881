import React, { Component } from 'react';
import { Button, Card, CardBody, ListGroup, ListGroupItem, Modal } from 'reactstrap';
import Moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import LoadingBar from '../Utilities/LoadingBar';
import AuthService from '../Authentication/AuthService';
import EventModal from './EventModal';

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.Auth = new AuthService();

    this.state = {
      activeEvent: null,
      events: [],
      displayModal: false
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  /**
   * @description Retrieves events from the /events endpoint
   */
  getEvents = async () => {
    this.setState({ loading: true });
    try {
      const res = await this.Auth.fetch('/calendar', { method: 'GET' });
      res.data.sort((a, b) => {
        return a.start < b.start ? -1 : 1;
      });
      this.setState({
        events: res.data,
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Closes the modal
   */
  closeModal = () => {
    this.setState({
      displayModal: false,
      activeEvent: null
    });
    this.getEvents();
  };

  render() {
    const { activeEvent, displayModal, events, loading } = this.state;

    return (
      <div className='component-container'>
        <div className='component-header card'>
          {loading && <LoadingBar />} 
          {' '}
          <div className='component-header-text'>Calendar</div>
        </div>
        <div className='component-body'>
          <Card>
            <Modal
              isOpen={displayModal}
              size='lg'
            >
              <EventModal
                event={activeEvent}
                display={displayModal}
                closeModal={this.closeModal}
                update={this.updateactiveEvent}
                add={this.addEvent}
                save={this.updateEvent}
                remove={this.deleteEvent}
              />
            </Modal>
            <CardBody>
              <ListGroup>
                <ListGroupItem className='d-flex align-items-center'>
                  <Button
                    outline
                    color='success'
                    size='sm'
                    onClick={() =>
                      this.setState({
                        displayModal: true,
                        activeEvent: {}
                      })
                    }
                  >
                    Add New Event
                  </Button>
                </ListGroupItem>
                {events.map((event) => {
                  return (
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center'
                      key={event.eventId}
                    >
                      <div>
                        {Moment(event.start).isSame(event.end, 'day') ? (
                          <strong>{`${Moment(event.start).format('LLL')} - ${Moment(event.end).format('LT')}`}</strong>
                        ) : (
                          <strong>{`${Moment(event.start).format('LL')} - ${Moment(event.end).format('LL')}`}</strong>
                        )}
                        <h5>
                          {`${event.title} - ${event.region} `}
                          {event.olympics && (
                            <>
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='solid'
                              />
                              <FontAwesomeIcon
                                icon='trophy'
                                data-tip='This is a Northstrat Olympic event'
                              />
                            </>
                          )}
                        </h5>
                        <span>
                          <FontAwesomeIcon icon='map-pin' /> 
                          {' '}
                          {event.location}
                        </span>
                      </div>
                      <Button
                        outline
                        color='primary'
                        size='sm'
                        onClick={() =>
                          this.setState({
                            displayModal: true,
                            activeEvent: { ...event }
                          })
                        }
                      >
                        Edit
                      </Button>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
