import React from 'react';

const LoadingIcon = (props) => {
  const { size, inline } = props;
  return (
    <div className={`loading-icon ${size} ${inline ? 'inline' : ''}`}>
      <div className='crescent red' />
      <div className='crescent blue' />
    </div>
  );
};

export default LoadingIcon;
