import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import AuthService from './AuthService';
import LoadingIcon from '../Utilities/LoadingIcon';
import logo from '../../images/ns-logo.png';

const environment = process.env.REACT_APP_STAGE;

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();

    this.state = {
      email: '',
      password: '',
      loading: false
    };
  }

  componentWillMount() {
    if (this.Auth.loggedIn() && this.Auth.isAdmin()) {
      const { history } = this.props;
      history.push('/home');
    }
  }

  /**
   * @description Submits the form
   * @param {SyntheticEvent} e
   */
  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { email, password } = this.state;
    try {
      await this.Auth.login(email.toLowerCase(), password);
      const { history } = this.props;
      const isAdmin = this.Auth.isAdmin();
      if (isAdmin) {
        history.push('/messages');
      } else {
        toast.error('Only administrators are authorized to sign in to this website.', { autoClose: false });
        this.setState({
          email: '',
          password: '',
          loading: false
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString(), { autoClose: false });
    }
  };

  /**
   * @description Navigates to the sign up form
   */
  goToSignUp = () => {
    const { history } = this.props;
    history.push('/signup');
  };

  /**
   * @description Navigates to the confirmation form
   */
  goToConfirm = () => {
    const { history } = this.props;
    history.push('/confirm');
  };

  render() {
    const { email, password, loading } = this.state;
    return (
      <Card className='centered-card'>
        {environment !== 'prod' && (
          <h3 style={{ textAlign: 'center' }}>{`${environment === 'test' ? 'Test' : environment} Environment`}</h3>
        )}
        {loading && <LoadingIcon size='md' />}
        <CardBody>
          <CardTitle
            className='text-center'
            tag='h3'
          >
            <img
              src={logo}
              width='auto'
              height='50'
              alt='Northstrat'
            />
          </CardTitle>
          <Form onSubmit={this.submitForm}>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input
                type='email'
                name='email'
                id='email'
                onChange={e => this.setState({ email: e.target.value })}
                value={email}
              />
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <Input
                type='password'
                name='password'
                id='password'
                onChange={e => this.setState({ password: e.target.value })}
                value={password}
              />
            </FormGroup>
            <FormGroup>
              <Button
                type='submit'
                color='primary'
                disabled={!email || !password}
              >
                Login
              </Button>
              <Button
                color='secondary'
                onClick={this.goToSignUp}
              >
                Create Account
              </Button>
            </FormGroup>
          </Form>
          <Button
            color='link'
            onClick={this.goToConfirm}
          >
            Have a confirmation code? Click here
          </Button>
        </CardBody>
      </Card>
    );
  }
}
